import { ContainerRecoverPassword } from '@components';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import React from 'react';
import { WrapperRecoverPassword } from './styled';
import Viewport from '../../../shared/hooks/viewport';
import { graphql, useStaticQuery } from 'gatsby';

type TProps = {
    token: string;
};

const RecoverPassword = (props: TProps) => {
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Iniciar sesión',
            pathname: 'iniciar-sesion/'
        },
        {
            crumbLabel: 'Recuperar contraseña',
            pathname: 'iniciar-sesion/recuperar-contrasena/'
        }
    ];

    const DataRecoveryPassword = useStaticQuery(graphql`
        query DataRecoveryPassword {
            allContentfulPaginaInternaFooter(filter: {page: {eq: "RecuperarContraseña"}}) {
                nodes {
                    title
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ title, metaData }]
        }
    } = DataRecoveryPassword;



    return (
        <Layout>
            <SEO
                slug="/iniciar-sesion/recuperar-contrasena/"
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
            />
            <WrapperRecoverPassword>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}

                <ContainerRecoverPassword />
            </WrapperRecoverPassword>
        </Layout>
    );
};

export default RecoverPassword;
